<script setup lang="ts">
import BandSignupInit from '~/components/band/signup/init.vue'
import MismatchModals from '~/components/draft/popup/mismatchModals.vue'
import IntercomButton from '~/components/intercom/button.vue'
import SnackbarCenter from '~/components/layout/snackbarCenter.vue'
import NavIndex from '~/components/nav/index.vue'
import IncompleteSignupBanner from '~/components/nav/warnings/IncompleteSignupBanner.vue'

import { useNotificationSocket } from '~/composables/useNotificationSocket'

import { useLoginStore } from '~/stores/login'
import { useMiscBandSignupStore } from '~/stores/miscBandSignup'
import { useMiscDraftStore } from '~/stores/miscDraft'
import { useMiscMenuStore } from '~/stores/miscMenu'
import { useMiscModalStore } from '~/stores/miscModal'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useNotificationV2Store } from '~/stores/notificationV2'
import { useRoutingStore } from '~/stores/routing'
import { useSubmissionsStore } from '~/stores/submissions'
import { useUserStore } from '~/stores/user'
import { useUserFavoritesStore } from '~/stores/userFavorites'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { Breakpoints } from '~/enums/breakpoints'

interface ClassCheckConfig {
  url: string[]
  sub?: number
  query?: { key: string; value: string }[]
}

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})
useHead(
  (() => ({
    ...head.value,
    link: head.value.link
      ? head.value.link.filter((e: unknown) => {
          return (
            typeof e === 'object' &&
            e !== null &&
            'hreflang' in e &&
            typeof e.hreflang === 'string' &&
            !['en-US', 'fr-FR'].includes(e.hreflang)
          )
        })
      : [],
  }))(),
)
const route = useRoute()
const { IS_LOGGED_IN, IS_INCOMPLETE_USER } = storeToRefs(useUserStore())
const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { fullScreen: MENU_FULL_SCREEN } = storeToRefs(useMiscMenuStore())
const { inspectedInfluencers: BUCKET_INSPECTED_INFLUENCERS } = storeToRefs(
  useUserFavoritesStore(),
)
const { mismatchModals: MISMATCH_MODALS } = storeToRefs(useMiscDraftStore())
const { openCount: OPEN_MODALS } = storeToRefs(useMiscModalStore())
const { SETUP_EVENT_LISTENERS } = useMiscResizeStore()
const { SET_PREVIOUS: SET_PREVIOUS_ROUTE } = useRoutingStore()
const { SET_INSPECTED_INFLUENCERS } = useUserFavoritesStore()
const { SET_SELECTED_SUBMISSIONS_IDS } = useSubmissionsStore()
const { display: LOGIN_IS_DISPLAYED } = storeToRefs(useLoginStore())
const { SET_DISPLAY: LOGIN_SET_DISPLAY } = useLoginStore()
const { isTransitioningToSignup: IS_MID_PAGE_TRANSITION } = storeToRefs(
  useMiscBandSignupStore(),
)
const navExcluded: ClassCheckConfig[] = [
  { url: ['/draft/influencers'] },
  { url: ['/influencer/profile', 'preview'] },
  { url: ['/draft/', '/track/'], sub: Breakpoints.lg },
  { url: ['/draft/', '/shared-music/'], sub: Breakpoints.lg },
  { url: ['/draft/', '/recap/'], sub: Breakpoints.lg },
  { url: ['/draft/', '/messages/'], sub: Breakpoints.lg },
  { url: ['/draft/', '/selection/'], sub: Breakpoints.lg },
  { url: ['/draft/', '/influencers/'], sub: Breakpoints.lg },
  {
    url: ['/agency/', '/favorites/'],
    query: [{ key: 'fromStep', value: '1a' }],
  },
]

const urlWhereNavNoShadow: ClassCheckConfig[] = [
  { url: ['/draft/', '/influencers/'] },
]

const displayNav = computed<boolean>(
  () =>
    !navExcluded.some((e) => {
      const out =
        e.url.every((_e) => route.path.includes(_e)) &&
        (!e.query ||
          e.query.every((query) => {
            return route.query[query.key] === query.value
          }))

      if (out && typeof e.sub === 'number') return SCREEN_WIDTH.value < e.sub
      else return out
    }),
)

const navNoShadowClass = computed<Record<string, boolean>>(() => ({
  navNoShadow: urlWhereNavNoShadow.some((config) => {
    return config.url.every((urlChunk) => route.path.includes(urlChunk))
  }),
}))

const shouldDisplayIncompleteSignupBanner = computed(() => {
  if (route.path.includes('/signup/')) return false
  return IS_INCOMPLETE_USER.value && !IS_MID_PAGE_TRANSITION.value
})

function closeBucketPopup() {
  SET_INSPECTED_INFLUENCERS(null)
  SET_SELECTED_SUBMISSIONS_IDS([])
}

function triggerPageVisitEvent(): void {
  if (import.meta.client) {
    trackEvent(
      {
        category: 'Visit',
        action: 'Page Visit',
      },
      route,
    )
  }
}

function handleModalStateChange(openCount: number) {
  const hasOpenModal = openCount > 0
  if (hasOpenModal) return document.body.classList.add('tw-overflow-hidden')

  document.body.classList.remove('tw-overflow-hidden')
}

function pageChange(_to: string, from: string): void {
  triggerPageVisitEvent()
  SET_PREVIOUS_ROUTE(from)
}

/**
 * When there is a next query open the login and it will automaticaly redirct to the next url.
 */
function checkForNextQuery() {
  const {
    query: { next },
  } = route

  if (!next || IS_LOGGED_IN.value) return
  LOGIN_SET_DISPLAY(true)
}

watch(() => route.fullPath, pageChange)
watch(OPEN_MODALS, handleModalStateChange)

const { addSocketEvent, removeSocketEvent } = useNotificationSocket()

const { UPDATE_BY_ID } = useNotificationV2Store()
let notificationListenerUid: null | string = null

onBeforeUnmount(() => {
  if (!notificationListenerUid) return
  removeSocketEvent(notificationListenerUid)
})

onMounted(() => {
  triggerPageVisitEvent()
  SETUP_EVENT_LISTENERS()
  checkForNextQuery()
  notificationListenerUid = addSocketEvent((notification) => {
    UPDATE_BY_ID(notification)
  })
})
</script>

<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <div class="layoutWrapper default">
      <transition>
        <LazyAuthLogin
          v-if="LOGIN_IS_DISPLAYED"
          :model-value="LOGIN_IS_DISPLAYED"
          class="inLayoutIgnoreFontUpscale ignoreFontUpscale"
          @update:model-value="LOGIN_SET_DISPLAY"
        />
      </transition>
      <BandSignupInit class="inLayoutIgnoreFontUpscale ignoreFontUpscale" />
      <NavIndex
        v-if="displayNav"
        class="inLayoutIgnoreFontUpscale ignoreFontUpscale"
      />
      <IncompleteSignupBanner
        v-if="shouldDisplayIncompleteSignupBanner"
        :key="$route.fullPath"
      />
      <IntercomButton />
      <MismatchModals
        v-if="
          MISMATCH_MODALS.mismatchModal.isDisplayed ||
          MISMATCH_MODALS.selectionMismatchModal.isDisplayed
        "
      />
      <div :class="{ noHeight: MENU_FULL_SCREEN, ...navNoShadowClass }">
        <slot />
      </div>
      <LazyAgencyFavoritesBucketPopup
        v-if="BUCKET_INSPECTED_INFLUENCERS"
        :influencers="BUCKET_INSPECTED_INFLUENCERS"
        class="inLayoutIgnoreFontUpscale ignoreFontUpscale"
        @close="closeBucketPopup"
      />
      <ClientOnly>
        <!-- Teleport modal backdrops here -->
        <div id="modal-backdrop-overlay" />
      </ClientOnly>
      <SnackbarCenter class="inLayoutIgnoreFontUpscale ignoreFontUpscale" />
    </div>
  </Html>
</template>

<style lang="scss" scoped>
.noHeight {
  overflow: hidden;
  height: 0px;
}
</style>
